<div class="pad">
    <div class="sticky">
        <div class="container d-flex flex-row align-items-center justify-content-between">
            <div class="overflow">
                <header class="d-flex justify-content-between contain" [class.shadow]="shadow">
                    <div class="d-flex h-100 left">
                        <button (click)="showNav = true" *ngIf="!backTo && !showNav"><i class="im-icon im-icon-bars transform-y-1"></i></button>
                        <button *ngIf="backTo" (click)="goBackTo()"><i class="im-icon im-icon-arrow-left transform-y-1"></i></button>
                    </div>
                    <a class="logo" [routerLink]="['/']"><img src="/assets/img/misc/logo-cropped.svg" alt="logo"></a>
                    <div class="d-flex h-100 right">
                        <button [routerLink]="['/basket']" [queryParams]="getParams()" *ngIf="!showNav" class="position-relative"><i class="icon icon-basket"></i><span *ngIf="getBasketQty()" class="count">{{getBasketQty()}}</span></button>
                        <button (click)="showNav = false" *ngIf="!backTo && showNav"><i class="im-icon im-icon-x-light transform-y-1"></i></button>
                    </div>
                </header>
            </div>
        </div>
    </div>
</div>

<nav class="opacity-hide" [class.opacity-show]="showNav">
    <div class="container d-flex flex-column flex-grow-1">
        <div class="d-flex flex-column flex-grow-1">
            <ul>
                <li><a (click)="showNav = false" class="waves-effect waves-mediumlighter d-flex align-items-center" [routerLink]="['/']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i style="font-size: 24px;" class="im-icon im-icon-home"></i><span>{{'header_home' | translate}}</span></a></li>
                <li><a (click)="showNav = false" class="waves-effect waves-mediumlighter d-flex align-items-center" [routerLink]="['/my-products']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i style="font-size: 27px;" class="im-icon im-icon-dispenser"></i><span>{{'header_products' | translate}}</span></a></li>
                <li><a (click)="showNav = false" class="waves-effect waves-mediumlighter d-flex align-items-center" [routerLink]="['/order-history']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"><i style="font-size: 22px;" class="im-icon im-icon-receipt-copy"></i><span>{{'header_order_history' | translate}}</span></a></li>
            </ul>
            <ul>
                <li class="logout"><a class="waves-effect waves-mediumlighter d-flex align-items-center" (click)="logout()"><i style="font-size: 21px; margin-top: 1px;" class="im-icon im-icon-logout"></i><span>{{'header_logout' | translate}}</span></a></li>
                <li class="logout"><a class="waves-effect waves-mediumlighter d-flex align-items-center" href="{{HelpersService.getSiteLink()}}"><div style="width: 40px; height: 100%;" class="d-flex align-items-center"><i class="icon icon-fys"></i></div><span>{{'header_back_to_website' | translate}}</span></a></li>
            </ul>
        </div>
        <div class="bottom">
            <a href="{{HelpersService.getSiteLink('terms-and-conditions')}}" target="_blank">{{'header_terms' | translate}}</a>
        </div>
    </div>
</nav>