/**
 * fysioshop-bestelapp
 * fysioshop-bestelapp api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    id: number;
    language: User.LanguageEnum;
    email: string;
    tel: string;
    vatnr: string;
    company: string;
    firstname: string;
    lastname: string;
    client_id: number;
    klanttype: User.KlanttypeEnum;
    showPrice: boolean;
    roles?: Array<string>;
}
export namespace User {
    export type LanguageEnum = 'du' | 'fr';
    export const LanguageEnum = {
        Du: 'du' as LanguageEnum,
        Fr: 'fr' as LanguageEnum
    };
    export type KlanttypeEnum = 'kinesist' | 'particulier' | 'sportverzorger';
    export const KlanttypeEnum = {
        Kinesist: 'kinesist' as KlanttypeEnum,
        Particulier: 'particulier' as KlanttypeEnum,
        Sportverzorger: 'sportverzorger' as KlanttypeEnum
    };
}


