import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { BasketComponent } from './pages/basket/basket.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { MyProductsDetailComponent } from './pages/my-products-detail/my-products-detail.component';
import { MyProductsComponent } from './pages/my-products/my-products.component';
import { OrderConfirmedComponent } from './pages/order-confirmed/order-confirmed.component';
import { OrderHistoryDetailComponent } from './pages/order-history-detail/order-history-detail.component';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { LeaveGuardService } from './services/leave-guard.service';

const routes: Routes = [
    { path: 'login', component: LoginComponent, data: { isPublic: true } },
    // dashboard
    { path: 'dashboard', redirectTo: '', pathMatch: 'full' },
    {
        path: '',
        component: DashboardComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    // order history
    {
        path: 'order-history',
        component: OrderHistoryComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: 'dashboard' }
    },
    {
        path: 'order-history/:id',
        component: OrderHistoryDetailComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: 'order-history' }
    },
    // my products
    {
        path: 'my-products',
        component: MyProductsComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: 'dashboard' }
    },
    {
        path: 'my-products/:id',
        component: MyProductsDetailComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: 'my-products' }
    },
    // basket
    {
        path: 'basket',
        component: BasketComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: 'my-products' }
    },
    // addresses
    {
        path: 'addresses',
        component: AddressesComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: 'basket' }
    },
    // order confirmed
    {
        path: 'order-confirmed',
        component: OrderConfirmedComponent,
        canDeactivate: [LeaveGuardService],
        data: { backTo: null }
    },
    // 404
    { path: '**', redirectTo: '/' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
