<div class="container page d-flex flex-column my-products-detail">
    <div class="contain">
        <div *ngIf="!ready" class="mt-xxl mb-l">
            <app-spinner></app-spinner>
        </div>
    </div>
    <ng-container *ngIf="ready">

        <div class="contain">
            <div class="swipe" (swipeleft)="swipe($event.type)" (swiperight)="swipe($event.type)">
                <span class="qty" *ngIf="getQty()">{{getQty()}}</span>
                <ngb-carousel *ngIf="slides && slides.length" tabindex="-1">
                    <ng-template ngbSlide *ngFor="let slide of slides">
                    <div tabindex="-1" class="wrapper">
                        <img [src]="slide" onerror="this.src='assets/img/misc/product-placeholder.svg';" alt="product image">
                    </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
        
        <div class="description-container p-m flex-grow-1">
            <h1>{{product.product | display}}</h1>
            <div class="article">{{product.article | display}} <span class="cat">{{getCategories()}}</span></div>
            <div class="d-flex justify-content-between align-items-center mt-xs mb-xs">
                <div class="price --price">{{product.price | customCurrency}}</div>
                <div class="count d-flex align-items-center">
                    <button (click)="minus()" class="button default waves-effect waves-light"><i class="im-icon im-icon-minus"></i></button>
                    <span>{{formSTR.qty}}</span>
                    <button (click)="plus()" class="button default waves-effect waves-light"><i class="im-icon im-icon-plus"></i></button>
                </div>
            </div>
            <div class="description mb-s" *ngIf="product.shortDescr" [innerHTML]="product.shortDescr"></div>
            <button *ngIf="!addTimeout" (click)="addToBasket()" style="min-height: 46px;" class="button green w-100 waves-effect waves-light mt-s">{{'myproductsdetail_add_to_basket' | translate}}</button>
            <div *ngIf="addTimeout" (click)="false" class="button green button--added w-100 mt-s">
                <div class="loading"></div>
                <div style="z-index: 1;" class="d-flex align-items-center"><i class="im-icon im-icon-check"></i> {{'myproductsdetail_added_to_basket' | translate}}</div>
            </div>
        </div>
            
    </ng-container>
</div>