import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { ResourceService } from '../../services/resource.service';

@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
    styleUrls: ['./addresses.component.scss']
})
export class AddressesComponent implements OnInit {
    addresses: any[] = [];
    addressIndex: number = null;
    ready: boolean = false;

    constructor(
        private HelpersService: HelpersService,
        private ResourceService: ResourceService,
        public Route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
        this.addresses = this.ResourceService.getAddresses();
        this.addressIndex = this.ResourceService.getAddressIndex();
        setTimeout(() => {
            this.ready = true;
        }, 500);
    }

    selectAddress(index) {
        if (index != undefined) {
            this.addressIndex = parseInt(index);
            this.ResourceService.storeAddressIndex(parseInt(index));
        }
    }
}
