import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';

@Component({
    selector: 'app-order-confirmed',
    templateUrl: './order-confirmed.component.html',
    styleUrls: ['./order-confirmed.component.scss']
})
export class OrderConfirmedComponent implements OnInit {
    constructor(public Route: ActivatedRoute, public HelpersService: HelpersService, public Router: Router) {}

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
    }
}
