import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from './config.service';

import { tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import { Config } from '../models/common';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);
    private config: Config;

    constructor(private ConfigService: ConfigService, private DefaultService: DefaultService) {
        this.config = this.ConfigService.getConfig();
    }

    public login(FORM: any) {
        return this.DefaultService.userLogin(FORM).pipe(tap((next) => {}));
        // return Observable.of(null).pipe(tap((next) => {}));
    }

    public fetchUser(): any {
        return this.DefaultService.userMe().pipe(
            tap((next) => {
                this.setUser(next);
            })
        );

        return Observable.of((observer) => {
            observer.next(null);
        }).pipe(
            tap((next) => {
                this.setUser({ firstname: 'Imengine', lastname: 'Temp' });
            })
        );
    }

    public logout() {
        // window.location.href = this.config.loginRedirectUrl;
        this.DefaultService.userLogout().subscribe((next) => {
            window.location.href = '/login';
        });
    }

    public onUnauth() {
        if (window.location.pathname.indexOf('/login') == -1) {
            window.location.href = '/login';
        }
    }

    public getUser(): Observable<object> {
        return this.user$.asObservable();
    }

    public getUserValue() {
        return this.user$.getValue();
    }

    private setUser(user: object) {
        this.user$.next(user);
    }
}
