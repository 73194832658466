<div class="container page pb-m">
    <div class="contain">
        <h2 class="mb-s">{{'orderhistorydetail_title' | translate}} #{{order.number}}</h2>
        <div *ngIf="!ready" class="mt-xxl mb-l">
            <app-spinner></app-spinner>
        </div>
        <ng-container *ngIf="ready">
            <div class="row display-row mw-400-px">
                <div class="col-12">
                    <div class="display-content">
                        <div class="display-label">{{'orderhistorydetail_date' | translate}}</div>
                        <div class="display-value">
                            <span>{{order.orderDate | formatTs: 'dddd D MMMM YYYY' | capitalizeFirstletter}} {{'general_on_ts' | translate}} {{order.orderDate | formatTs: 'HH:mm'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row display-row mw-450-px">
                <div class="col-4">
                    <div class="display-content">
                        <div class="display-label">{{'orderhistorydetail_order_number' | translate}}</div>
                        <div class="display-value">
                            <span>{{order.number | display}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="display-content">
                        <div class="display-label">{{'orderhistorydetail_ordered_by' | translate}}</div>
                        <div class="display-value">
                            <span>{{order.name | display}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row display-row mw-450-px pb-xxxs">
                <div class="col-12">
                    <div class="display-content">
                        <div class="display-label">{{'orderhistorydetail_delivery_address' | translate}}</div>
                        <div class="display-value">
                            <div style="margin-bottom: 3px;" *ngIf="order.address.name">{{order.address.name}}</div>
                            <span>{{order.address.street}} {{order.address.streetnr}}<span *ngIf="order.address.streetbus"> {{'orderhistorydetail_bus' | translate}} {{order.address.streetbus}}</span>, {{order.address.city}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row display-row mw-450-px" *ngIf="order.comment">
                <div class="col-12">
                    <div class="display-content">
                        <div class="display-label">{{'orderhistorydetail_comment' | translate}}</div>
                        <div class="display-value">
                            <span>{{order.comment | display}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row display-row mw-450-px" *ngIf="order.poNumber">
                <div class="col-12">
                    <div class="display-content">
                        <div class="display-label">{{'orderhistorydetail_po_number' | translate}}</div>
                        <div class="display-value">
                            <span>{{order.poNumber | display}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sep"></div>
            <h3 class="mb-s">{{'orderhistorydetail_order_title' | translate}}</h3>
            <app-product *ngFor="let product of order.products" [product]="product"></app-product>
            <div class="sep mb-xs --price--block"></div>
            <div class="fontsize-l mb-m --price--block">
                <div class="d-flex justify-content-between mb-s">
                    <span>{{'general_total_excl_vat' | translate}}</span>
                    <span>{{order.totalExcl | customCurrency}}</span>
                </div>
                <div class="d-flex justify-content-between mb-s">
                    <span>{{'general_vat' | translate}}</span>
                    <span>{{order.vat | customCurrency}}</span>
                </div>
                <div class="d-flex justify-content-between fontweight-600">
                    <span>{{'general_total' | translate}}</span>
                    <span>{{order.total | customCurrency}}</span>
                </div>
            </div>
            <div class="button-default-shadow">
                <button (click)="orderAgain()" class="button default w-100 waves-effect waves-light mt-s">{{'orderhistorydetail_order_again_btn' | translate}}</button>
            </div>
        </ng-container>
    </div>
</div>