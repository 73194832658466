import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { ConfigService } from '../../../services/config.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ResourceService } from '../../../services/resource.service';
import { HelpersService } from '../../../services/helpers.service';
import { HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BasketService } from '../../../services/basket.service';
import { query } from '@angular/animations';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    shadow: boolean = false;
    showNav: boolean = false;
    routes: any;
    backTo: any;
    previousState: any;
    basket: any = [];

    constructor(
        public ConfigService: ConfigService,
        public Route: ActivatedRoute,
        public Router: Router,
        public AuthenticationService: AuthenticationService,
        private ResourceService: ResourceService,
        public HelpersService: HelpersService,
        public BasketService: BasketService
    ) {}

    ngOnInit(): void {
        // console.log(this.Route.data);
        this.HelpersService.getBackTo().subscribe((next) => {
            this.backTo = next;
        });
        this.HelpersService.getPreviousState().subscribe((next) => {
            this.previousState = next;
        });
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                this.HelpersService.setBackTo(null);
            }
        });
        this.BasketService.getBasketObservable().subscribe((next) => {
            this.basket = next;
        });
    }

    getBasketQty() {
        let qty = 0;
        this.basket.forEach((product) => {
            qty += product.qty || 0;
        });
        return qty;
    }

    goBackTo() {
        const backToParam = this.HelpersService.getParam('backto');
        if (backToParam) {
            this.Router.navigate(['/' + backToParam]);
        } else {
            this.Router.navigate(['/' + this.backTo]);
        }
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        if (windowScroll > 0) {
            this.shadow = true;
        } else {
            this.shadow = false;
        }
    }

    getParams() {
        let params: any = { backto: window.location.pathname.replace(/^\/+/, '') || 'dashboard' };
        return params;
    }

    logout() {
        this.AuthenticationService.logout();
    }
}
