import { Pipe, PipeTransform } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TranslatorService } from '../services/translator.service';
import * as moment from 'moment';
import 'moment/locale/nl-be';
moment.locale('nl-be');

const keywordAt = 'om';
const keywordToday = 'vandaag';
const today = moment();

@Pipe({ name: 'formatTs' })
export class formatTsPipe implements PipeTransform {
    transform(ts: string, format: string): string {
        if (!ts || !format || !moment(ts).isValid()) {
            return ts || '-';
        }
        switch (format) {
            case 'prettyTs':
                return moment(ts).calendar(null, {
                    sameElse: 'DD-MM-YYYY'
                });
                break;

            default:
                break;
        }
        return moment(ts).format(format);
    }
}
@Pipe({ name: 'capitalizeFirstletter' })
export class capitalizeFirstletterPipe implements PipeTransform {
    transform(value: any, type): any {
        if (!value) return value;
        return value[0].toUpperCase() + value.substr(1).toLowerCase();
    }
}
@Pipe({ name: 'display' })
export class displayPipe implements PipeTransform {
    transform(value: any, type): any {
        if (value === 0) {
            return 0;
        }
        if (!value) {
            return '-';
        } else return value;
    }
}
@Pipe({ name: 'customCurrency' })
export class customCurrencyPipe implements PipeTransform {
    constructor(private cp: CurrencyPipe) {}
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '€ 0,00';
        }
        if (!value) {
            return '-';
        } else {
            // // float
            // if (value % 1 !== 0) {
            //     console.log('float');
            // }
            // // number
            // if (value % 1 === 0) {
            //     console.log('number');
            // }
            let returnValue = this.cp.transform(parseFloat(value).toString(), '€ ').replace(/,/g, '.');
            const n = returnValue.lastIndexOf('.');
            if (n >= 0 && returnValue.length) {
                returnValue = returnValue.substring(0, n) + ',' + returnValue.substring(n + 1);
            }
            return returnValue;
        }
    }
}
@Pipe({ name: 'customPercentage' })
export class percentagePipe implements PipeTransform {
    transform(value: any): any {
        if (value === 0 || value === '0') {
            return '0%';
        }
        if (!value) {
            return '-';
        } else return value.toString().replace('.', ',') + '%';
    }
}

@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {
    constructor(private TranslatorService: TranslatorService) {}
    public transform(key: string): string {
        return this.TranslatorService.getTranslation(key);
    }
}
