<div class="container page page--login">
    <div *ngIf="!ready" class="pt-xxl mb-l">
        <app-spinner></app-spinner>
    </div>
    <ng-container *ngIf="ready">
        <img class="logo" src="assets/img/misc/logo.svg" alt="Fysioshop logo">
        <h1>{{'login_title' | translate}}</h1>
        <div class="form">
            <div class="row row-form mb-m">
                <div class="col-md-12">
                    <app-field-text 
                        [model]="formSTR.email" 
                        (modelChange)="formSTR.email = $event"
                        (enter)="login()"
                        typeClass="login"
                        [type]="'email'"
                        label="{{'login_email' | translate}}"
                        placeholder="{{'login_email_placeholder' | translate}}">
                    </app-field-text>
                </div>
            </div>
            <div class="row row-form mb-0 pt-1">
                <div class="col-md-12 position-relative">
                    <a tabindex="-1" class="forgot-password" href="{{HelpersService.getSiteLink('lost-password')}}">{{'login_forgot_password_link' | translate}}</a>
                    <app-field-text 
                        [model]="formSTR.password" 
                        (modelChange)="formSTR.password = $event"
                        (enter)="login()"
                        typeClass="login"
                        type="password"
                        label="{{'login_password' | translate}}"
                        placeholder="{{'login_password_placeholder' | translate}}">
                    </app-field-text>
                    <span *ngIf="validation.password" class="error-field-display">{{validation.password}}</span>
                </div>
            </div>
        </div>
        <div class="button-default-shadow mb-m">
            <button (click)="login()" [class.disabled]="saving" class="button default w-100 waves-effect waves-light">{{'login_login_btn' | translate}}</button>
        </div>
        <div class="text-center">
            <div class="language-switch d-inline-flex align-items-center">
                <span>{{'login_choose_language' | translate}}</span>
                <app-field-select 
                    id="language-switch"
                    [model]="language" 
                    [options]="languageOptions"
                    (modelChange)="setLanguage($event)" 
                    arrowFit="/assets/img/icons/icon-dropdown-darker.svg"
                    label=""
                    [error]=""
                    placeholder="">
                </app-field-select>
            </div>
        </div>
        <div class="foot">
            <span>{{'login_issues' | translate}}</span>
            <a class="d-inline-block" href="mailto:info@fysioshop.be">{{'login_contact_helpdesk' | translate}}</a>
        </div>
    </ng-container>
</div>