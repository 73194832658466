import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { ProductService } from '../../services/product.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { BasketService } from '../../services/basket.service';
import { ResourceService } from '../../services/resource.service';

@Component({
    selector: 'app-my-products-detail',
    templateUrl: './my-products-detail.component.html',
    styleUrls: ['./my-products-detail.component.scss'],
    providers: [NgbCarouselConfig]
})
export class MyProductsDetailComponent implements OnInit {
    @ViewChild(NgbCarousel) carousel;

    currentId: number;
    ready: boolean = false;
    addTimeout: boolean = false;
    product: any = {};
    slides: any[] = [];
    formSTR: any = {};
    basket: any = [];
    categories: any[] = [];

    constructor(
        public Route: ActivatedRoute,
        public HelpersService: HelpersService,
        private ProductService: ProductService,
        config: NgbCarouselConfig,
        public BasketService: BasketService,
        private ResourceService: ResourceService
    ) {
        config.interval = -1;
        config.keyboard = false;
        config.pauseOnHover = false;
        config.animation = true;
        config.showNavigationArrows = false;
    }

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
        this.currentId = this.Route.snapshot.params['id'];
        this.categories = this.ResourceService.getCategories();
        this.ProductService.getProducts({
            RPP: 1,
            startRow: 0,
            id: this.currentId
        }).subscribe((next) => {
            this.product = next.data[0];
            // console.log(this.product);
            this.slides = this.product.media.map((media) => {
                return media.full;
            });
            this.formSTR = { qty: 1, ...this.product };
            this.ready = true;
        });
        this.BasketService.getBasketObservable().subscribe((next) => {
            this.basket = next;
        });
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 1);
    }

    swipe(e) {
        if (e === 'swiperight') {
            this.carousel.prev();
        } else {
            this.carousel.next();
        }
    }

    minus() {
        if (this.formSTR.qty > 1) {
            this.formSTR.qty--;
        }
    }

    plus() {
        this.formSTR.qty++;
    }

    addToBasket() {
        this.BasketService.addToBasket(this.formSTR);
        this.formSTR = { qty: 1, ...this.product };
        this.addTimeout = true;
        setTimeout(() => {
            this.addTimeout = false;
        }, 1000);
    }

    getQty() {
        const index = this.basket
            .map((item) => {
                return item.id;
            })
            .indexOf(parseInt(this.currentId.toString()));
        if (index != -1) {
            console.log(this.basket[index].qty);
            return this.basket[index].qty;
        }
    }

    getCategories() {
        let string = '- ';
        // console.log(this.product);
        // console.log(this.categories);
        const categories = this.categories
            .filter((cat) => {
                for (let i = 0; i < cat.ids.length; i++) {
                    const id = cat.ids[i];
                    if (this.product.catIds.indexOf(id) != -1) {
                        return true;
                    }
                }
            })
            .map((cat) => {
                return cat.cat;
            })
            .join(', ');
        string += categories;
        return string;
    }
}
