import { Component, OnInit } from '@angular/core';
import { BasketService } from '../../services/basket.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { ResourceService } from '../../services/resource.service';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'app-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {
    ready: boolean = false;
    saving: boolean = false;
    editMode: boolean = false;
    basket: any = [];
    validBasket: any = [];
    orderInfo: any = {};
    address: any;
    validation: any = {};
    formSTR: any = {};
    updateBasket: any;

    constructor(
        public Route: ActivatedRoute,
        public HelpersService: HelpersService,
        public BasketService: BasketService,
        public ResourceService: ResourceService,
        public Router: Router,
        public ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
        this.address = this.ResourceService.getActiveAddress();
        this.BasketService.getBasketObservable().subscribe((next) => {
            this.basket = next;
        });
        this.updateBasket = this.debounce(() => this.initBasket());
        this.initBasket();
    }

    initBasket() {
        if (this.basket.length) {
            this.BasketService.getValidBasket().subscribe((next) => {
                this.validBasket = next.products;
                this.orderInfo = next;
                this.ready = true;
            });
        } else {
            this.validBasket = [];
            this.ready = true;
        }
    }

    getValidProducts() {
        return this.basket.filter((product) => {
            const index = this.validBasket
                .map((item) => {
                    return item.id;
                })
                .indexOf(product.id);
            return index != -1;
        });
    }

    getInvalidProducts() {
        return this.basket.filter((product) => {
            const index = this.validBasket
                .map((item) => {
                    return item.id;
                })
                .indexOf(product.id);
            return index == -1;
        });
    }

    submitIsDisabled() {
        return this.saving || !this.formSTR.conditionsCheck || !this.formSTR.priceCheck;
    }

    submit() {
        this.saving = true;
        let FORM = {
            address: this.address,
            data: this.getValidProducts().map((item) => {
                return { id: item.id, qty: item.qty };
            }),
            comment: this.formSTR.comment,
            poNumber: this.formSTR.poNumber
        };
        this.BasketService.confirmOrder(FORM).subscribe(
            (next) => {
                this.BasketService.emptyBasket();
                this.Router.navigate(['/order-confirmed']);
            },
            (error) => {
                if (error.error && error.error.details) {
                    this.validation = error.error.details;
                }
                this.saving = false;
            }
        );
    }

    removeProduct(id) {
        this.BasketService.removeFromBasket(id);
        this.updateBasket();
    }

    updateProduct($event) {
        this.BasketService.updateProduct($event);
        this.updateBasket();
    }

    debounce(func, timeout = 400) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }
}
