<div class="container page pb-m">
    <div class="contain">
        <h1>{{'dashboard_welcome' | translate}} {{user.firstname}}</h1>
        <div class="basket-msg d-flex" *ngIf="basket.length">
            <div class="basket-icon flex-shrink-0"></div>
            <div>
                <div class="msg">{{'dashboard_basket_msg' | translate}}</div>
                <div class="d-flex btns">
                    <!-- <div> -->
                        <button [routerLink]="['/my-products']" class="button small-btn waves-effect waves-light" style="margin-right: 10px;">{{'dashboard_continue_shopping_btn' | translate}}</button>
                    <!-- </div> -->
                    <!-- <div> -->
                        <button [routerLink]="['/basket']" [queryParams]="{backto:'dashboard'}" class="button small-btn--filled waves-effect waves-light">{{'dashboard_to_basket_btn' | translate}}</button>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div class="cta">
            <h2>{{'dashboard_new_order_cta' | translate}}</h2>
            <div class="cta-button button-gray-shadow">
                <button [routerLink]="['/my-products']" class="button default waves-effect waves-light">{{'dashboard_order_now_btn' | translate}}</button>
            </div>
            <!-- <span class="c"></span> -->
            <span class="bg"></span>
        </div>
        <div *ngIf="!ready" class="mt-xl mb-l">
            <app-spinner></app-spinner>
        </div>
        <ng-container *ngIf="ready">
            <div class="d-flex justify-content-between align-items-end mb-xs">
                <h2>{{'dashboard_order_history' | translate}}</h2>
                <a *ngIf="orderHistory.length" class="passive-link" style="transform: translateY(-3px);" [routerLink]="['/order-history']">{{'dashboard_show_all' | translate}}</a>
            </div>
            <div *ngIf="orderHistory.length">
                <div class="orders d-flex">
                    <ng-container *ngFor="let order of orderHistory">
                        <app-order-tile [order]="order" [backTo]="'dashboard'"></app-order-tile>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="!orderHistory.length" class="empty-state">
                <div>
                    {{'dashboard_order_history_empty_state' | translate}}
                </div>
                <button [routerLink]="['/my-products']" class="button default--light waves-effect waves-light">{{'dashboard_empty_state_btn' | translate}}</button>
            </div>
        </ng-container>
    </div>
</div>