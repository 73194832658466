<div class="container page d-flex flex-column pb-m">
    <div class="contain d-flex flex-column flex-grow-1">
        <h2 class="">{{'addresses_title' | translate}}</h2>
        <div class="subtitle mb-xs">{{'addresses_subtitle' | translate}}</div>
        <div *ngIf="!ready" class="mt-xxl mb-l">
            <app-spinner></app-spinner>
        </div>
        <div class="flex-grow-1 opacity-hide" [class.opacity-show]="ready">
            <div *ngFor="let address of addresses; let index = index" (click)="selectAddress(index)" class="arrow-line">
                <div class="d-flex">
                    <div class="d-flex align-items-center">
                        <img src="/assets/img/icons/icon-delivery.svg" alt="delivery address" class="mr-xs">
                    </div>
                    <div class="flex-grow-1">
                        <div class="arrow-line-main" style="margin-bottom: 2px;">
                            <div style="margin-bottom: 1px;" *ngIf="address.name">{{address.name}}</div>
                            {{address.street}} {{address.streetnr}}<span *ngIf="address.streetbus"> {{'basket_bus' | translate}} {{address.streetbus}}</span>
                        </div>
                        <div class="arrow-line-sub">
                            {{address.postalcode}} {{address.city}}
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <span class="rad" [class.active]="index == addressIndex"><i></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>