import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { ResourceService } from '../../services/resource.service';
import { ProductService } from '../../services/product.service';
import { BasketService } from '../../services/basket.service';
import { ConfigService } from '../../services/config.service';

@Component({
    selector: 'app-my-products',
    templateUrl: './my-products.component.html',
    styleUrls: ['./my-products.component.scss']
})
export class MyProductsComponent implements OnInit {
    ready: boolean = false;
    categories: any[] = [];
    products: any[] = [];
    term: string = '';
    shadow: boolean = false;
    activeCategory: string = '';
    headerOffset: number = 128;
    formSTR: any = null;
    basket: any = [];

    constructor(
        private HelpersService: HelpersService,
        private Route: ActivatedRoute,
        private ResourceService: ResourceService,
        private ProductService: ProductService,
        public BasketService: BasketService,
        public ConfigService: ConfigService
    ) {}

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
        this.categories = this.ResourceService.getCategories();
        this.ProductService.getProducts({ RPP: 9999 }).subscribe((next) => {
            this.products = next.data.map((product) => {
                return { categoryString: this.getCategoryString(product), ...product };
            });
            // console.log(this.categories);
            // console.log(this.products);
            this.setActiveCategory();
            this.ready = true;
        });
        this.BasketService.getBasketObservable().subscribe((next) => {
            this.basket = next;
        });
    }

    getPadding() {
        const height = document.getElementById('height').offsetHeight + 6;
        if (!height) return '150px';
        const el: any = document.getElementsByClassName('products')[0];
        el.style.paddingTop = `${height}px`;
    }

    getCategoryString(product) {
        let categories = [];
        product.catIds.forEach((catId) => {
            this.categories.forEach((cat) => {
                if (cat.ids.indexOf(catId) != -1) {
                    categories.push(cat.cat);
                }
            });
        });
        return categories.join(',');
    }

    productsByCategory(catIds) {
        return this.getProducts().filter((product) => {
            for (let i = 0; i < product.catIds.length; i++) {
                const catId = product.catIds[i];
                if (catIds.indexOf(catId) != -1) {
                    return true;
                }
            }
            return false;
        });
    }

    getThumb(product) {
        for (let i = 0; i < product?.media?.length; i++) {
            const media = product.media[i];
            if (media && media.full) {
                return media.full;
            }
        }
        return '/assets/img/misc/product-placeholder.svg';
    }

    getCategories() {
        return this.categories.filter((category) => {
            return true;
        });
    }

    getProducts() {
        return this.products.filter((product) => {
            return (
                !this.term ||
                product.product.toLowerCase().indexOf(this.term.toLowerCase()) != -1 ||
                product.categoryString.toLowerCase().indexOf(this.term.toLowerCase()) != -1 ||
                product.artNr.toLowerCase().indexOf(this.term.toLowerCase()) != -1
            );
        });
    }

    showEmptyState() {
        return (
            this.categories.filter((category) => {
                return this.productsByCategory(category.ids).length;
            }).length === 0
        );
    }

    getCatId(ids: any[]) {
        return ids
            .map((item) => {
                return `category-${item}`;
            })
            .join(',');
    }

    @HostListener('window:scroll', ['$event'])
    handleScroll() {
        const windowScroll = window.pageYOffset;
        if (windowScroll > 0) {
            this.shadow = true;
        } else {
            this.shadow = false;
        }
        this.setActiveCategory();
    }

    setActiveCategory() {
        let activeCategory = '';
        const categories = this.categories.filter((category) => {
            return this.productsByCategory(category.ids).length;
        });
        if (!categories || !categories.length) {
            return;
        }
        let offset = 194;
        if (this.ConfigService.getConfig().isIE) {
            offset = 212;
        }
        let scrollPosition = (window.scrollY || window.pageYOffset) + offset;
        if (scrollPosition <= offset) {
            activeCategory = this.getCatId(categories[0].ids);
        }
        if (!activeCategory) {
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                const catId = this.getCatId(category.ids);
                const el = document.getElementById(catId);
                if (!el) return;
                const { offsetBottom, offsetTop } = this.getDimensions(el);
                if (scrollPosition > offsetTop && scrollPosition < offsetBottom) {
                    activeCategory = catId;
                }
            }
        }
        if (activeCategory) {
            this.activeCategory = activeCategory;
        }
    }

    getDimensions = (ele) => {
        const { height } = ele.getBoundingClientRect();
        const offsetTop = ele.offsetTop;
        const offsetBottom = offsetTop + height;
        return {
            height,
            offsetTop,
            offsetBottom
        };
    };

    scrollToElement(id): void {
        let el = null;
        if (id) {
            el = document.getElementById(id);
        }
        if (el) {
            if (!this.ConfigService.getConfig().isIE) {
                const offset = el.offsetTop - 192;
                window.scrollTo({ top: offset, behavior: 'smooth' });
            } else {
                const offset = el.offsetTop - 210;
                window.scroll(0, offset);
            }
        }
    }

    openStickyBasket(product) {
        this.formSTR = { qty: 1, ...product };
    }

    minus() {
        if (this.formSTR.qty > 1) {
            this.formSTR.qty--;
        }
    }

    plus() {
        this.formSTR.qty++;
    }

    addToBasket() {
        this.BasketService.addToBasket(this.formSTR);
        this.formSTR = null;
    }

    getQty(id) {
        const index = this.basket
            .map((item) => {
                return item.id;
            })
            .indexOf(id);
        if (index != -1) {
            return this.basket[index].qty;
        }
    }
}
