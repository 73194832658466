import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { ConfigService } from './config.service';
import { TranslatorService } from '../services/translator.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor(
        private DefaultService: DefaultService,
        private ConfigService: ConfigService,
        private TranslatorService: TranslatorService
    ) {}
    private categories$ = new BehaviorSubject<any>(null);
    private addresses$ = new BehaviorSubject<any>(null);
    private miscSettings$ = new BehaviorSubject<any>(null);
    private config$ = new BehaviorSubject<any>(null);
    private addressIndex: any = null;

    public init(): Observable<any>[] {
        const observables$: Observable<any>[] = [];

        const categoryObservable$ = this.DefaultService.resourcesGetAllCategories().pipe(
            tap((next) => {
                this.categories$.next(
                    next.data
                        .reduce(
                            (function (hash) {
                                return function (array, obj) {
                                    if (!hash[obj.cat]) array.push((hash[obj.cat] = obj));
                                    else hash[obj.cat].id += ',' + obj.id;
                                    return array;
                                };
                            })({}),
                            []
                        )
                        .map((item) => {
                            return {
                                cat: item.cat,
                                ids: item.id
                                    .toString()
                                    .split(',')
                                    .map((id) => {
                                        return parseInt(id);
                                    })
                            };
                        })
                );
            })
        );
        observables$.push(categoryObservable$);

        const addressesObservable$ = this.DefaultService.resourcesGetAdresses().pipe(
            tap((next) => {
                this.addresses$.next(next);
                this.fetchAddressIndex();
            })
        );
        observables$.push(addressesObservable$);

        const miscSettingsObservable$ = this.DefaultService.resourcesGetMiscSettings().pipe(
            tap((next) => {
                this.miscSettings$.next(next);
            })
        );
        observables$.push(miscSettingsObservable$);

        return observables$;
    }

    public initPublic(): Observable<any>[] {
        const observables$: Observable<any>[] = [];

        const miscSettingsObservable$ = this.DefaultService.resourcesGetMiscSettings().pipe(
            tap((next) => {
                this.miscSettings$.next(next);
            })
        );
        observables$.push(miscSettingsObservable$);

        return observables$;
    }

    public getCategories(): any[] {
        return this.categories$.getValue();
    }

    public getAddresses(): any[] {
        return this.addresses$.getValue();
    }

    public getMiscSettings(): any {
        return this.miscSettings$.getValue();
    }

    public getActiveAddress() {
        return this.addresses$.getValue()[this.addressIndex];
    }

    public getAddressIndex() {
        return this.addressIndex;
    }

    public getTypeByCode(code: string): string {
        switch (code) {
            case 'unique':
                return 'Uniek';
            case 'group':
                return 'Groep';
            default:
                return '';
        }
    }

    public getDiscountOnByCode(code: string): string {
        switch (code) {
            case 'all':
                return 'Alles';
            case 'category':
                return 'Categorie';
            case 'product':
                return 'Product';
            default:
                return '';
        }
    }

    public getLocale() {
        return this.TranslatorService.getLocale();
    }

    public getDayLabels() {
        return { su: 'Zo', mo: 'Ma', tu: 'Di', we: 'Wo', th: 'Do', fr: 'Vr', sa: 'Za' };
    }

    public storeAddressIndex(index?) {
        if (index != undefined) {
            this.addressIndex = index;
        }
        localStorage.setItem('addressIndex', this.addressIndex);
    }
    public fetchAddressIndex() {
        if (localStorage.getItem('addressIndex') !== null && localStorage.getItem('addressIndex') !== undefined) {
            this.addressIndex = parseInt(localStorage.getItem('addressIndex'));
            if (!this.addresses$.getValue()[this.addressIndex]) {
                this.addressIndex = 0;
                this.storeAddressIndex();
            }
        } else {
            this.addressIndex = 0;
            this.storeAddressIndex();
        }
    }
}
