import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { OrderService } from '../../services/order.service';
import { BasketService } from '../../services/basket.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    user: any;
    orderHistory: any[] = [];
    ready: boolean = false;
    basket: any = [];

    constructor(
        public AuthenticationService: AuthenticationService,
        public OrderService: OrderService,
        public BasketService: BasketService
    ) {}

    ngOnInit(): void {
        this.AuthenticationService.getUser().subscribe((next: any) => {
            this.user = { ...next };
        });
        this.OrderService.getOrderHistory({
            RPP: 10
        }).subscribe((next) => {
            this.orderHistory = next.data;
            this.ready = true;
        });
        this.BasketService.getBasketObservable().subscribe((next) => {
            this.basket = next;
        });
    }
}
