<div class="container page basket d-flex flex-column pb-l">
    <div class="contain">
        <h2 class="mb-s">{{'basket_title' | translate}}</h2>
        <div *ngIf="!ready" class="mt-xxl mb-l">
            <app-spinner></app-spinner>
        </div>
        <ng-container *ngIf="ready && basket.length">
            <!-- invalid products -->
            <ng-container *ngIf="getInvalidProducts().length">
                <div class="d-flex warn">
                    <div class="warn-i">
                        <i class="im-icon im-icon-warning"></i>
                    </div>
                    <div class="flex-grow-1 warn-product-not-available">
                        {{'basket_warn_product_not_available' | translate}}
                    </div>
                </div>
                <app-product *ngFor="let product of getInvalidProducts()" [product]="product" [invalid]="true" (remove)="removeProduct(product.id)"></app-product>
                <div class="sep sep--black" style="margin-top: 24px; margin-bottom: 24px;"></div>
            </ng-container>
            <!-- valid products -->
            <ng-container *ngIf="getValidProducts().length">
                <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 22px;">
                    <h3>{{'basket_products' | translate}}</h3>
                    <button class="button-link fontsize-s" (click)="editMode ? editMode = false : editMode = true"><span *ngIf="!editMode">{{'basket_edit' | translate}}</span><span *ngIf="editMode">{{'basket_ready' | translate}}</span></button>
                </div>
                <!-- products -->
                <app-product *ngFor="let product of getValidProducts()" [editMode]="editMode" [product]="product" (update)="updateProduct($event)" (remove)="removeProduct(product.id)"></app-product>
                <div class="sep --price--block" style="margin-top: 24px; margin-bottom: 24px;"></div>
                <!-- price -->
                <div class="fontsize-l mb-m --price--block">
                    <div class="d-flex justify-content-between mb-s">
                        <span>{{'general_total_excl_vat' | translate}}</span>
                        <span>{{orderInfo.totalExcl | customCurrency}}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-s">
                        <span>{{'general_vat' | translate}}</span>
                        <span>{{orderInfo.vat | customCurrency}}</span>
                    </div>
                    <div class="d-flex justify-content-between fontweight-600">
                        <span>{{'general_total' | translate}}</span>
                        <span>{{orderInfo.total | customCurrency}}</span>
                    </div>
                </div>
                <!-- address -->
                <h3>{{'basket_delivery_address' | translate}}</h3>
                <a [routerLink]="['/addresses']" class="arrow-line waves-effect waves-mediumlighter address mb-s" [ngStyle]="this.ConfigService.getConfig().isIE && {'min-height': '80px'}">
                    <div class="d-flex">
                        <div class="d-flex align-items-center">
                            <img src="/assets/img/icons/icon-delivery.svg" alt="delivery address" class="mr-xs">
                        </div>
                        <div>
                            <div class="arrow-line-main" style="margin-bottom: 2px;">
                                <div style="margin-bottom: 2px;" *ngIf="address.name">{{address.name}}</div>
                                {{address.street}} {{address.streetnr}}<span *ngIf="address.streetbus"> {{'basket_bus' | translate}} {{address.streetbus}}</span>
                            </div>
                            <div class="arrow-line-sub">
                                {{address.postalcode}} {{address.city}}
                            </div>
                            <i class="im-icon im-icon-chev-right"></i>
                        </div>
                    </div>
                </a>
                <!-- comment -->
                <div class="row row-form" style="margin-bottom: 29px;">
                    <div class="col-md-12">
                        <h3 style="padding-bottom: 6px;">{{'basket_comment_label' | translate}} <span>({{'general_optional' | translate}})</span></h3>
                        <app-field-textarea [error]="validation.comment" [placeholder]="'basket_comment_placeholder' | translate" (modelChange)="formSTR.comment = $event" [model]="formSTR.comment"></app-field-textarea>
                    </div>
                </div>
                <!-- PO number -->
                <div class="row row-form" style="margin-bottom: 29px;">
                    <div class="col-md-12">
                        <h3 style="padding-bottom: 6px;">{{'basket_poNumber_label' | translate}} <span>({{'general_optional' | translate}})</span></h3>
                        <app-field-text [error]="validation.poNumber" [placeholder]="'basket_poNumber_placeholder' | translate" (modelChange)="formSTR.poNumber= $event" [model]="formSTR.poNumber"></app-field-text>
                    </div>
                </div>
                <label class="check-line d-flex mb-s">
                    <input [(ngModel)]="formSTR.conditionsCheck" type="checkbox" class="flex-shrink-0">
                    <span>{{'basket_conditions_check_label_1' | translate}} <a href="{{HelpersService.getSiteLink('terms-and-conditions')}}" target="_blank">{{'basket_conditions_check_label_2' | translate}}</a>{{'basket_conditions_check_label_5' | translate}}</span>
                </label>
                <label class="check-line d-flex mb-s">
                    <input [(ngModel)]="formSTR.priceCheck" type="checkbox" class="flex-shrink-0">
                    <span>{{'basket_price_check_label' | translate}}</span>
                </label>
                <!-- submit -->
                <div class="d-flex flex-column align-items-center pt-xxs">
                    <div [class.button-default-shadow]="!submitIsDisabled()" class="mb-s w-100">
                        <button (click)="submit()" [class.disabled]="submitIsDisabled()" class="button default waves-effect waves-light w-100">{{'basket_submit_btn' | translate}}</button>
                    </div>
                    <a [routerLink]="['/my-products']" [queryParams]="{backto:'basket'}">{{'basket_back_to_products' | translate}}</a>
                </div>
            </ng-container>
        </ng-container>
        
        <!-- empty state -->
        <ng-container *ngIf="ready && !getValidProducts().length">
            <div class="empty-state">
                <div>
                    {{'basket_empty_state' | translate}}
                </div>
                <button [routerLink]="['/my-products']" [queryParams]="{backto:'basket'}" class="button default--light waves-effect waves-light">{{'basket_empty_state_btn' | translate}}</button>
            </div>
        </ng-container>
    </div>
</div>