import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { OrderService } from '../../services/order.service';
import { BasketService } from '../../services/basket.service';

@Component({
    selector: 'app-order-history-detail',
    templateUrl: './order-history-detail.component.html',
    styleUrls: ['./order-history-detail.component.scss']
})
export class OrderHistoryDetailComponent implements OnInit {
    currentId: number;
    ready: boolean = false;
    order: any = {};

    constructor(
        public Route: ActivatedRoute,
        public Router: Router,
        public HelpersService: HelpersService,
        private OrderService: OrderService,
        private BasketService: BasketService
    ) {}

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
        this.currentId = this.Route.snapshot.params['id'];
        this.OrderService.getOrderHistory({
            RPP: 1,
            startRow: 0,
            id: this.currentId
        }).subscribe((next) => {
            this.order = next.data[0];
            // console.log(this.order);
            this.ready = true;
        });
    }

    orderAgain() {
        let newBasket = [...this.order.products].map((item) => {
            return { ...item, qty: item.orderInfo.qty, price: item.orderInfo.price, vat: item.orderInfo.vat };
        });
        this.BasketService.setBasket(newBasket);
        this.Router.navigate(['/basket']);
    }
}
