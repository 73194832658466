import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class BasketService {
    private basket$ = new BehaviorSubject<object>([]);

    constructor(private DefaultService: DefaultService, private AuthenticationService: AuthenticationService) {}

    public getValidBasket() {
        return this.DefaultService.orderGetCurrentOrderOverview({ data: this.getBasket() }).pipe(
            tap((next: any) => {
                let newBasket = this.getBasket();
                newBasket.forEach((product) => {
                    const index = next.products
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(product.id);
                    if (index != -1) {
                        product.qty = next.products[index].orderInfo.qty;
                        product.price = next.products[index].orderInfo.price;
                    }
                });
                this.setBasket(newBasket);
            })
        );
    }

    public getBasketObservable(): Observable<object> {
        return this.basket$.asObservable();
    }

    public getBasket(): any {
        return this.basket$.getValue();
    }

    public setBasket(basket: object) {
        this.basket$.next(basket);
        this.storeBasket();
    }

    public addToBasket(product) {
        let newBasket = this.getBasket();
        const index = this.getBasket()
            .map((item) => {
                return item.id;
            })
            .indexOf(product.id);
        if (index == -1) {
            newBasket.push(product);
        } else {
            newBasket[index].qty += product.qty;
        }
        this.setBasket(newBasket);
    }

    public updateProduct(product) {
        let newBasket = this.getBasket();
        const index = this.getBasket()
            .map((item) => {
                return item.id;
            })
            .indexOf(product.id);
        if (index == -1) {
            return;
        } else {
            newBasket[index].qty = product.qty;
        }
        this.setBasket(newBasket);
    }

    public removeFromBasket(id) {
        const index = this.getBasket()
            .map((item) => {
                return item.id;
            })
            .indexOf(id);
        if (index != -1) {
            let newBasket = this.getBasket();
            newBasket.splice(index, 1);
            this.setBasket(newBasket);
        }
    }

    public emptyBasket() {
        this.setBasket([]);
    }

    public storeBasket() {
        localStorage.setItem('basket', JSON.stringify(this.getBasket()));
        localStorage.setItem('basket_email', this.AuthenticationService.getUserValue()['email'] || null);
    }
    public fetchBasket() {
        if (localStorage.getItem('basket')) {
            this.basket$.next(JSON.parse(localStorage.getItem('basket')));
        }
        if (
            localStorage.getItem('basket_email') &&
            localStorage.getItem('basket_email') != this.AuthenticationService.getUserValue()['email']
        ) {
            this.emptyBasket();
        }
    }

    public confirmOrder(FORM) {
        return this.DefaultService.orderConfirmOrder(FORM).pipe(
            tap((next: any) => {
                // console.log(next);
            })
        );
    }
}
