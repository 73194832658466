import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    constructor(private DefaultService: DefaultService) {}

    public getOrderHistory(searchSTR: any) {
        return this.DefaultService.ordersGetOrderHistory(searchSTR);
    }
}
