<div class="product-line d-flex">
    <div class="d-flex flex-row flex-grow-1 pr-xxs">
        <div class="amount" *ngIf="!editMode">
            {{product.qty || product.orderInfo.qty || 0}}x
        </div>
        <div>
            <div *ngIf="editMode" class="count d-flex align-items-center">
                <button (click)="minus()" class="button default waves-effect waves-light"><i class="im-icon im-icon-minus"></i></button>
                <span>{{formSTR.qty}}</span>
                <button (click)="plus()" class="button default waves-effect waves-light"><i class="im-icon im-icon-plus"></i></button>
            </div>
        </div>
        <div class="thumb show--tablet" [ngStyle]="{'background-image': 'url(&quot;' + getThumb() + '&quot;)'}"></div>
        <div>
            <div class="main">
                {{product.product | display}}
            </div>
            <div class="sub">
                {{product.article | display}}
            </div>
        </div>
    </div>
    <div class="order-total --price" *ngIf="!invalid">
        {{((product.price || product.price === 0) ? product.price : product.orderInfo?.price) | customCurrency}}
    </div>
    <div class="order-delete" *ngIf="invalid">
        <button class="button-link" (click)="remove.emit()"><span>{{'productcomponent_delete' | translate}}</span></button>
    </div>
    <div class="order-delete-editmode" *ngIf="editMode">
        <button class="button-link" (click)="remove.emit()" [ngStyle]="true && {'display': 'block'}"><i class="im-icon im-icon-trash" [ngStyle]="true && {'transform': 'translateY(0)'}"></i></button>
    </div>
</div>