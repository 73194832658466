import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-order-tile',
    templateUrl: './order-tile.component.html',
    styleUrls: ['./order-tile.component.scss']
})
export class OrderTileComponent implements OnInit {
    @Input() order: any;
    @Input() backTo: string;

    constructor() {}

    ngOnInit(): void {}

    getThumb() {
        for (let i = 0; i < this.order.products.length; i++) {
            const product = this.order.products[i];
            if (product.media && product?.media?.length) {
                return product.media[0].full;
            }
        }
        return '/assets/img/misc/product-placeholder.svg';
    }

    getParams() {
        let params: any = {};
        if (this.backTo) {
            params.backto = this.backTo;
        }
        return params;
    }
}
