import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

import * as Hammer from 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { AuthenticationInterceptor } from './interceptors/authentication.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldTextComponent } from './components/fields/field-text/field-text.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldToggleComponent } from './components/fields/field-toggle/field-toggle.component';
import { FieldTextareaComponent } from './components/fields/field-textarea/field-textarea.component';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { ApiModule, Configuration, ConfigurationParameters } from './utils/api';
import { FieldSwitchComponent } from './components/fields/field-switch/field-switch.component';
import { PopoverComponent } from './components/common/popover/popover.component';
import { ClickOutsideDirective } from './utils/directives';
import { EnterTheViewportNotifierDirective } from './utils/directives';
import {
    displayPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    capitalizeFirstletterPipe
} from './utils/pipes';
import { GlobalErrorHandler } from './utils/errorHandler';
import { FieldSelectComponent } from './components/fields/field-select/field-select.component';
import { ShimmerComponent } from './components/misc/shimmer/shimmer.component';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ModeSwitchComponent } from './components/misc/mode-switch/mode-switch.component';
import { PaginationComponent } from './components/misc/pagination/pagination.component';
import { ConfirmDeleteComponent } from './components/misc/confirm-delete/confirm-delete.component';
import { FieldHorizontalRadioComponent } from './components/fields/field-horizontal-radio/field-horizontal-radio.component';
import { FieldRadiosComponent } from './components/fields/field-radios/field-radios.component';
import { FieldTypeaheadComponent } from './components/fields/field-typeahead/field-typeahead.component';
import { FieldDateComponent } from './components/fields/field-date/field-date.component';
import { LoginComponent } from './pages/login/login.component';
import { FieldCurrencyComponent } from './components/fields/field-currency/field-currency.component';
import { InlineSpinnerComponent } from './components/misc/inline-spinner/inline-spinner.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { OrderTileComponent } from './components/common/order-tile/order-tile.component';
import { OrderHistoryComponent } from './pages/order-history/order-history.component';
import { OrderHistoryDetailComponent } from './pages/order-history-detail/order-history-detail.component';
import { ProductComponent } from './components/common/product/product.component';
import { MyProductsComponent } from './pages/my-products/my-products.component';
import { MyProductsDetailComponent } from './pages/my-products-detail/my-products-detail.component';
import { BasketComponent } from './pages/basket/basket.component';
import { AddressesComponent } from './pages/addresses/addresses.component';
import { OrderConfirmedComponent } from './pages/order-confirmed/order-confirmed.component';

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        swipe: { direction: Hammer.DIRECTION_ALL }
    };
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        SpinnerComponent,
        FieldTextComponent,
        FieldToggleComponent,
        FieldTextareaComponent,
        FieldSwitchComponent,
        PopoverComponent,
        ClickOutsideDirective,
        EnterTheViewportNotifierDirective,
        formatTsPipe,
        displayPipe,
        capitalizeFirstletterPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        FieldSelectComponent,
        ShimmerComponent,
        ModeSwitchComponent,
        PaginationComponent,
        ConfirmDeleteComponent,
        FieldHorizontalRadioComponent,
        FieldRadiosComponent,
        FieldTypeaheadComponent,
        FieldDateComponent,
        LoginComponent,
        FieldCurrencyComponent,
        InlineSpinnerComponent,
        DashboardComponent,
        OrderTileComponent,
        OrderHistoryComponent,
        OrderHistoryDetailComponent,
        ProductComponent,
        MyProductsComponent,
        MyProductsDetailComponent,
        BasketComponent,
        AddressesComponent,
        OrderConfirmedComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        AngularMyDatePickerModule,
        CommonModule,
        HammerModule,
        ToastrModule.forRoot({
            timeOut: 5000,
            extendedTimeOut: 5000,
            positionClass: 'toast-bottom-right',
            closeButton: true,
            // disableTimeOut: true,
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning'
            }
        }),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },

        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
