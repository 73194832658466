import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService } from '../../../services/config.service';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
    @Input() product: any;
    @Input() invalid: boolean = false;
    @Input() editMode: boolean = false;
    @Output() remove = new EventEmitter();
    @Output() update = new EventEmitter();

    formSTR: any = {};

    constructor(public ConfigService: ConfigService) {}

    ngOnInit(): void {
        this.formSTR = { ...this.product };
    }

    minus() {
        if (this.formSTR.qty > 1) {
            this.formSTR.qty--;
            this.update.emit(this.formSTR);
        }
    }

    plus() {
        this.formSTR.qty++;
        this.update.emit(this.formSTR);
    }

    getThumb() {
        for (let i = 0; i < this.product?.media?.length; i++) {
            const media = this.product.media[i];
            if (media && media.full) {
                return media.full;
            }
        }
        return '/assets/img/misc/product-placeholder.svg';
    }
}
