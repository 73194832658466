<div class="container page d-flex flex-column">
    <ng-container *ngIf="ready">
        <div class="sticky" id="height">
            <div class="container">
                <div class="overflow">
                    <div class="contain" [class.shadow]="shadow">
                        <h2 class="mb-xxxs">{{'myproducts_title' | translate}}</h2>
                        <div class="h-tags d-flex">
                            <ng-container *ngFor="let category of categories">
                                <ng-container *ngIf="productsByCategory(category.ids).length">
                                    <span (click)="scrollToElement(getCatId(category.ids))" [class.active]="activeCategory && activeCategory === getCatId(category.ids)" class="h-tag">{{category.cat}}</span>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="position-relative">
                            <app-field-text 
                                [model]="term" 
                                (modelChange)="term = $event; this.setActiveCategory();" 
                                [instant]="true"
                                label=""
                                typeClass="search"
                                placeholder="{{'myproducts_search_placeholder' | translate}}">
                            </app-field-text>
                            <i *ngIf="term" (click)="term = ''; setActiveCategory();" class="im-icon im-icon-x-light"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="contain d-flex flex-column flex-grow-1">
        <div *ngIf="!ready" class="mt-xxl mb-l">
            <app-spinner></app-spinner>
        </div>
        <ng-container *ngIf="ready">
            <!-- <div class="products flex-grow-1" [ngStyle]="this.ConfigService.getConfig().isIE && {'padding-top': '150px'}"> -->
            <div class="products flex-grow-1" [ngStyle]="getPadding() && {'padding-top': getPadding()}">
                <div class="category" *ngFor="let category of getCategories()" id="{{getCatId(category.ids)}}">
                    <div *ngIf="productsByCategory(category.ids).length" class="category-head">{{category.cat | display}}</div>
                    <a [routerLink]="['/my-products', product.id]" class="product" *ngFor="let product of productsByCategory(category.ids)">
                        <div class="product-inner waves-effect-- waves-mediumlighter-- w-100 d-flex">
                            <div class="thumb-container">
                                <span class="qty" *ngIf="getQty(product.id)">{{getQty(product.id)}}</span>
                                <div class="thumb" [ngStyle]="{'background-image': 'url(&quot;' + getThumb(product) + '&quot;)'}"></div>
                            </div>
                            <div class="flex-grow-1" style="margin-top: 2px;">
                                <div class="name">{{product.product | display}}</div>
                                <div class="article">{{product.article | display}}</div>
                                <div class="price --price">{{product.price | customCurrency}}</div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="button-default-shadow" style="margin-top: 5px;">
                                    <button (click)="openStickyBasket(product);$event.stopPropagation();$event.preventDefault();" class="button default waves-effect waves-light"><i class="im-icon im-icon-plus"></i></button>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="empty-state" *ngIf="showEmptyState()">
                    <div>{{'myproducts_emptystate_main' | translate}} <span>{{term}}</span></div>
                    <div class="sub">{{'myproducts_emptystate_sub' | translate}}</div>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- sticky button -->
    <ng-container *ngIf="ready">
        <div class="sticky-button opacity-hide" [class.opacity-show]="basket && basket.length">
            <div class="container">
                <div class="overflow">
                    <div class="contain">
                        <div class="button-default-shadow">
                            <button [routerLink]="['/basket']" class="button default w-100 waves-effect waves-light">{{'myproducts_basket_btn' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- sticky basket -->
    <ng-container *ngIf="ready">
        <div class="sticky-basket opacity-hide" [class.opacity-show]="formSTR">
            <div class="container">
                <div class="overflow">
                    <div class="contain">
                        <ng-container *ngIf="formSTR">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <div class="name">{{formSTR.product | display}}</div>
                                    <div class="article">{{formSTR.article | display}}</div>
                                </div>
                                <div class="d-flex">
                                    <div class="price --price">{{formSTR.price | customCurrency}}</div>
                                    <i (click)="formSTR = null" class="im-icon im-icon-x"></i>
                                </div>
                            </div>
                            <div class="count d-flex align-items-center justify-content-center text-center">
                                <button (click)="minus()" class="button default waves-effect waves-light"><i class="im-icon im-icon-minus"></i></button>
                                <span>{{formSTR.qty}}</span>
                                <button (click)="plus()" class="button default waves-effect waves-light"><i class="im-icon im-icon-plus"></i></button>
                            </div>
                            <button (click)="addToBasket()" class="button green w-100 waves-effect waves-light">{{'myproducts_add_to_basket' | translate}}</button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- overlay -->
    <div class="overlay-container d-flex" (click)="formSTR = null" [class.active]="formSTR">
        <div class="container d-flex">
            <div class="overlay"></div>
        </div>
    </div>
</div>