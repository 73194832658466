import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ResourceService } from '../services/resource.service';
import { TranslatorService } from '../services/translator.service';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    backTo$ = new BehaviorSubject<string | null>(null);
    previousState$ = new BehaviorSubject<string | null>(null);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private toastr: ToastrService,
        private ResourceService: ResourceService,
        private TranslatorService: TranslatorService
    ) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public getParam(param: string): string {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param];
    }
    public addParam(param: string, value: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] == null || object[key] == undefined || object[key] == '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }
    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        if (successMessage) {
            this.toastr.info('', successMessage);
        }
    }
    public getBackTo() {
        return this.backTo$.asObservable();
    }
    public setBackTo(backTo: string | null) {
        setTimeout(() => {
            this.backTo$.next(backTo);
        }, 1);
    }
    public getPreviousState() {
        return this.previousState$.asObservable();
    }
    public setPreviousState(previousState: string | null) {
        this.previousState$.next(previousState);
    }
    public getSiteLink(path?) {
        let baseUrl = this.ResourceService.getMiscSettings().webshopUrl;
        let lang = this.TranslatorService.getCurrentLanguage();
        if (lang == 'du') lang = 'nl';
        if (!path) path = '';
        let url = `${baseUrl}${lang}/${path}`;
        return url;
    }
}
