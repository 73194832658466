import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ConfigService } from './services/config.service';
import { ResourceService } from './services/resource.service';
import { Config } from './models/common';
import { AuthenticationService } from './services/authentication.service';
import { TranslatorService } from './services/translator.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { HelpersService } from './services/helpers.service';
import { BasketService } from './services/basket.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    fetchedInitialRouteData: boolean = false;
    isPublic$ = new BehaviorSubject<boolean>(false);
    config: Config;
    hidePrice: boolean = true;

    constructor(
        public ConfigService: ConfigService,
        private TitleService: Title,
        private AuthenticationService: AuthenticationService,
        private TranslatorService: TranslatorService,
        private ResourceService: ResourceService,
        public Router: Router,
        public Route: ActivatedRoute,
        private HelpersService: HelpersService,
        private BasketService: BasketService
    ) {}

    ngOnInit(): void {
        this.config = this.ConfigService.getConfig();

        // listen to router events
        this.Router.events.subscribe((event) => {
            if (event instanceof RoutesRecognized) {
                let route = event.state.root.firstChild;
                this.HelpersService.setPreviousState(event.url);
                if (route && route.data && route.data.isPublic) {
                    this.isPublic$.next(true);
                } else {
                    this.isPublic$.next(false);
                }

                if (this.fetchedInitialRouteData) {
                    return;
                }

                if (!route || !route.data.isPublic) {
                    this.fetchedInitialRouteData = true;
                    this.AuthenticationService.fetchUser().subscribe((next) => {
                        this.initPrivateRoute(next.language);
                        if (next.showPrice) {
                            this.hidePrice = false;
                        }
                    });
                } else {
                    const publicLanguage = this.TranslatorService.getPublicLanguage();
                    this.initPublicRoute(publicLanguage);
                }
            }
        });

        setTimeout(() => {
            this.HelpersService.setPreviousState(null);
        }, 1);
    }

    public initPrivateRoute(language?) {
        this.BasketService.fetchBasket();

        const observables$: Observable<any>[] = [Observable.of(null)];
        observables$.push(...this.ResourceService.init());
        observables$.push(this.TranslatorService.init(language || 'du'));

        forkJoin(observables$).subscribe((next) => {
            this.setReady(true);
        });
    }

    public initPublicRoute(language?) {
        const observables$: Observable<any>[] = [Observable.of(null)];
        observables$.push(...this.ResourceService.initPublic());
        observables$.push(this.TranslatorService.init(language || 'du'));
        forkJoin(observables$).subscribe((next) => {
            this.setReady(true);
        });
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }

    public showHeader() {
        return !this.isPublic$.getValue();
    }
}
