import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { HelpersService } from '../../services/helpers.service';
import { TranslatorService } from '../../services/translator.service';
import { TranslatePipe } from '../../utils/pipes';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    ready: boolean = true;
    saving: boolean = false;
    formSTR: any = {};
    validation: any = {};
    language: string = 'du';
    languageOptions = [
        { title: this.TranslatePipe.transform('login_dutch'), value: 'du' },
        { title: this.TranslatePipe.transform('login_french'), value: 'fr' }
    ];

    constructor(
        private AuthenticationService: AuthenticationService,
        public route: ActivatedRoute,
        public router: Router,
        private TranslatorService: TranslatorService,
        private HelpersService: HelpersService,
        private TranslatePipe: TranslatePipe
    ) {}

    ngOnInit(): void {
        this.language = this.TranslatorService.getPublicLanguage();
    }

    login() {
        this.saving = true;
        this.AuthenticationService.login(this.formSTR).subscribe(
            (next) => {
                window.location.href = '/';
            },
            (error) => {
                this.validation = error.error.details;
                this.saving = false;
            }
        );
    }

    setLanguage(language: string) {
        this.ready = false;
        this.TranslatorService.setPublicLanguage(language || 'du');
        this.TranslatorService.init(language || 'du').subscribe((next) => {
            setTimeout(() => {
                this.language = this.TranslatorService.getPublicLanguage();
                this.validation = {};
                this.languageOptions = [
                    { title: this.TranslatePipe.transform('login_dutch'), value: 'du' },
                    { title: this.TranslatePipe.transform('login_french'), value: 'fr' }
                ];
                this.ready = true;
            }, 1000);
        });
    }
}
