<div class="container page d-flex flex-column pb-m">
    <div class="contain d-flex flex-column flex-grow-1">
        <h2 class="mb-xxxs">{{'orderhistory_title' | translate}} <ng-container *ngIf="ready && orderHistory.length">({{orderHistory.length}})</ng-container></h2>
        <div *ngIf="!ready" class="mt-xxl mb-l">
            <app-spinner></app-spinner>
        </div>
        <ng-container *ngIf="ready">
            <div class="flex-grow-1">
                <ul class="flex-grow-1">
                    <li *ngFor="let order of orderHistory">
                        <a [routerLink]="['/order-history', order.id]" class="arrow-line waves-effect waves-mediumlighter">
                            <div class="arrow-line-main">{{order.orderDate | formatTs: 'dddd D MMMM YYYY' | capitalizeFirstletter}}</div>
                            <div class="arrow-line-sub">
                                <span>#{{order.number}}</span>
                                <span class="dot-sep"></span>
                                <span>{{order.products.length}} <ng-container *ngIf="order.products.length == 1">{{'orderhistory_item' | translate}}</ng-container><ng-container *ngIf="order.products.length != 1">{{'orderhistory_items' | translate}}</ng-container></span>
                                <span class="dot-sep --price"></span>
                                <span class="--price">{{order.total | customCurrency}}</span>
                            </div>
                            <i class="im-icon im-icon-chev-right"></i>
                        </a>
                    </li>
                </ul>
                <div *ngIf="!orderHistory.length" class="empty-state">
                    <div>
                        {{'orderhistory_order_history_empty_state' | translate}}
                    </div>
                </div>
            </div>
            <div *ngIf="false" (visibilityChange)="inView($event)" enterTheViewportNotifier class="mt-m mb-m">
                <app-spinner *ngIf="ordersLoading"></app-spinner>
            </div>
            <div>
                <div class="button-default-shadow">
                    <button [routerLink]="['/my-products']" class="button default w-100 waves-effect waves-light mt-s">{{'orderhistory_new_order_btn' | translate}}</button>
                </div>
            </div>
        </ng-container>
    </div>
</div>