import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { HelpersService } from '../../services/helpers.service';
import { OrderService } from '../../services/order.service';

@Component({
    selector: 'app-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
    orderHistory: any[] = [];
    ready: boolean = false;
    ordersLoading: boolean = false;
    startRow: number = 0;
    totalRows: number = 0;

    constructor(
        public Route: ActivatedRoute,
        public HelpersService: HelpersService,
        private OrderService: OrderService
    ) {}

    ngOnInit(): void {
        this.HelpersService.setBackTo(this.Route.snapshot.data['backTo']);
        this.getOrderHistory();
    }

    getOrderHistory() {
        this.ordersLoading = true;
        this.OrderService.getOrderHistory({
            RPP: 300,
            startRow: this.startRow
        }).subscribe((next) => {
            this.totalRows = next.rows;
            this.orderHistory.push(...next.data);
            this.ready = true;
            this.ordersLoading = false;
        });
    }

    inView(visible) {
        if (visible && false) {
            this.startRow += 20;
            this.getOrderHistory();
        }
    }
}
