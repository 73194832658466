import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { TranslatorService } from '../services/translator.service';
import { TranslatePipe } from '../utils/pipes';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor(
        private toastr: ToastrService,
        private router: Router,
        private AuthenticationService: AuthenticationService,
        private TranslatorService: TranslatorService,
        public TranslatePipe: TranslatePipe
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedRequest = request.clone({
            headers: request.headers.append('Accept-Language', this.TranslatorService.getCurrentLanguage())
        });
        function failwhale(error) {
            const msg = `${error.error}`;
            if (document.getElementsByClassName('page') && document.getElementsByClassName('page').length) {
                document.getElementsByClassName('page')[0].innerHTML = msg;
            } else {
                document.getElementsByTagName('BODY')[0].innerHTML = msg;
            }
        }
        return next.handle(clonedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                switch (error.status) {
                    case 400:
                        // bad request / validation
                        // if (error.error && error.error.details && typeof error.error.details == 'string') {
                        //     this.toastr.error(error.error.details, 'Fout');
                        // } else
                        //     this.toastr.error(
                        //         this.TranslatePipe.transform('toast_validation_error_msg'),
                        //         this.TranslatePipe.transform('toast_validation_error_title')
                        //     );
                        break;
                    case 504:
                        this.redirectToLogin();
                        break;
                    case 401:
                        // unauthorized
                        this.redirectToLogin();
                        break;
                    case 500:
                        // internal server error
                        // this.toastr.error('Our team is notified and working on a solution', 'An error occured');
                        failwhale(error);
                        break;
                    case 403:
                        // this.toastr.error('You do not have access to this page', 'Forbidden');
                        // forbidden
                        failwhale(error);
                        break;
                    case 404:
                        // this.toastr.error('Page not found', '404');
                        // not found
                        failwhale(error);
                        break;
                }
                return throwError(error);
            })
        );
    }

    redirectToLogin() {
        this.AuthenticationService.onUnauth();
    }
}
